.title-panel {
  background-color: #f2f2f2;
  padding: 18px;
  position: relative;
  border-bottom: solid 1px #00000014;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.105);
  font-weight: 700;
  font-size: 18px;
}
.datePickerInput {
  z-index: 5;
}

.icon-demographics,
.icon-public{
 color: #7D60F5
}
.icon-whatsapp,
.icon-wa-solid{
 color: #4dc06a
}
.icon-messenger{
 color: #2196F3;
}

.wrapper-history {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.wrapper-history .channel i {
  margin-right: 10px;
  margin-left: 10px;
}
.wrapper-history .channel label.checkbox__Label-sc-16fa55e-1.culTPm {
  width: 20px;
  height: 20px;
  padding: 0;
}

.filter-mobile {
  display: none;
}

.container-icon-collapse-filter i {
  display: none;
}
.row.history label {
  margin: 10px 0px;
}
.row.history .relative-icon {
  position: relative;
  margin-top: -23px;
  margin-left: 98%;
}
.row.history .react-datepicker-popper {
  z-index: 7;
}
.row.history input {
  width: 100%;
  height: 26px;
  border-color: #d2d2d2;
  border-style: solid;
}

.row.history i.icon-cal {
  position: absolute;
  right: 0;
  z-index: 5;
}
@media (max-width: 756px) {
  .filter-mobile {
    display: flex;
  }
  .title-panel{
    justify-content: flex-start;
  }
  .container-icon-collapse-filter i {
    display: block;
  }
}
